import {Component, Input, Output, EventEmitter, AfterViewInit, OnInit} from "@angular/core";
import {JwtService} from "../../../../../shared/jwt.service";
import {HttpClient} from "@angular/common/http";
import {AppTimeServices} from "../../../../../shared/appTime.service";
import {Title} from "@angular/platform-browser";

export class ConfirmFoundsComponentParent implements AfterViewInit, OnInit{
  @Input() accountsDetails;
  @Input() tppName: string;
  @Input() accountNumber: string;
  @Input() sortCode: string;
  @Input() productName: string;
  @Input() accountName: string;
  @Input() accessExpiryDate: string;

  @Output() finishEvent: EventEmitter<null> = new EventEmitter();
  @Output() cancellationEvent: EventEmitter<null> = new EventEmitter();

  constructor(private titleService: Title) {
  }

  finish() {
    this.finishEvent.emit();
  }

  cancel() {
    this.cancellationEvent.emit();
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
  }
}
