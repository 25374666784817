const PAYMENT_DUPLICATED_CODE = "31123";

export const isPaymentDuplicated = (paymentResponse: any) => {
  return hasWarningStatus(paymentResponse) && hasPaymentDuplicatedStatusCode(paymentResponse);
};

function hasWarningStatus(paymentResponse) {
  return paymentResponse["fx-charges"] && paymentResponse["fx-charges"].statusInfo && paymentResponse["fx-charges"].statusInfo.statusType === 'Warning';
}

function hasPaymentDuplicatedStatusCode(paymentResponse) {
  return paymentResponse["fx-charges"].statusInfo.statusDetails && paymentResponse["fx-charges"].statusInfo.statusDetails.some((el) => {
    return el.code == PAYMENT_DUPLICATED_CODE;
  });
}
